import React from 'react';
import { Link } from 'gatsby';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import Divider from '@material-ui/core/Divider';

import * as classes from './price-card.module.scss';

export default function PriceCard({
  title,
  subheader,
  priceTitle,
  priceSubheader,
  features,
  actions,
  showLearnMore = false
}) {
  const FeatureListItem = ({ title }) => (
    <ListItem>
      <ListItemIcon>
        <CheckIcon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
  return (
    <Card classes={{ root: classes.priceCardRoot }}>
      <CardHeader
        classes={{ root: classes.priceCardHeaderRoot }}
        title={title}
        subheader={subheader}
      />
      <CardHeader
        classes={{ root: classes.priceCardHeaderRoot }}
        title={priceTitle}
        subheader={priceSubheader}
      />
      <CardContent classes={{ root: classes.priceCardContentRoot }}>
        <List>
          {features.map((feature, index) => <FeatureListItem key={index} title={feature} />)}
        </List>
      </CardContent>
      {showLearnMore && <div style={{ textAlign: 'center', paddingBottom: 20, marginTop: -20 }}>
        <Link to='/features'>Learn More</Link>
      </div>}
      <Divider />
      <CardActions>{actions}</CardActions>
    </Card>
  );
}