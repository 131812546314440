import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import PriceCard from '../components/price-card';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as classes from './pricing.module.scss';

const ADVANCED_PLAN_PRICE_USD = '2.99';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

export default function Pricing() {
  const handleGetStartedButtonClick = () => {
    return navigate('/checkout');
  };

  return (
    <Layout
      title={`Choose a plan`}
      description='TabManager.io is free for everyone to use with upgraded plans available for more advanced features.'
    >
      <div className={classes.root} style={{ paddingTop: 80 }}>
        <Container maxWidth='lg'>

          <Grid container justify='center' spacing={3}>
            <Grid item md={4} xs={12}>
              <PriceCard
                title={<h2>Free</h2>}
                subheader='Essential Features'
                priceTitle={
                  <div className={classes.priceCardFreePriceTitle}>
                    <h3>$0</h3>
                    <span>/month</span>
                  </div>
                }
                features={[
                  'Window Manager',
                  'Session Manager',
                  'Save Sessions & Windows',
                  'Session History',
                  'Basic Support'
                ]}
                actions={
                  <Button
                    style={{ padding: '16px 22px' }}
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled
                    fullWidth
                    disableElevation
                  >
                    Free
                  </Button>
                }
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <PriceCard
                title={<h2>Advanced</h2>}
                subheader='Advanced Features'
                priceTitle={
                  <div className={classes.priceCardAvancedPriceTitle}>
                    <h3>{currencyFormatter.format(ADVANCED_PLAN_PRICE_USD)}</h3>
                    <span>/month</span>
                  </div>
                }
                priceSubheader='Billed annually'
                features={[
                  'Everything in Free',
                  'Backup & Sync',
                  'Access your Work Anywhere',
                  'Priority Support'
                ]}
                actions={
                  <Button
                    style={{ padding: '16px 22px' }}
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    disableElevation
                    onClick={handleGetStartedButtonClick}
                  >
                    Get Started
                  </Button>
                }
                showLearnMore={true}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}
